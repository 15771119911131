$(document).ready(function () {
    var now = new Date();

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    function generateSelect2bs5() {

        $('.select2bs5').select2({
            theme: "bootstrap-5",
            dropdownParent: "#admin-modal-form"
        });
    }

    function generateSelect2bs5Modal() {

        $('.select2-bs5').select2({
            theme: "bootstrap-5",
            dropdownParent: "#modal-form .modal-content"
        });
    }

    function generateSelect2() {

        $('.select2').select2();
    }
    
    function generateSelect2bs5Multiple() {
        
        $('.select2bs5-multiple').select2({
            theme: "bootstrap-5",
            selectionCssClass: "select2--small", // For Select2 v4.1
            dropdownCssClass: "select2--small",
            placeholder:"select an option",
            tags: true,
            tokenSeparators: [',', ' ']
        });
        
    }

    function generateIconSelect2(){
        $("#template-icon").select2({
            templateResult: _ui_icon_template
        });
    }

    function _ui_icon_template(icon)
    {
        if (!icon.id) { return icon.text; }
        var $spanIcon = $(
         "<span ><i class='"+icon.element.value+"'></i> " + icon.text + "</span>"
        );
        console.log(icon.element.value);
        return $spanIcon;
    }

    function generateSelect2Ajax() {
        $('.select2-ajax').each(function () {
            var obj = $(this);
            obj.select2({
                ajax: {
                    url: obj.data('url'),
                    dataType: 'json',
                    delay: 250,
                    processResults: function (data) {
                        // Tranforms the top-level key of the response object from 'items' to 'results'
                        return {
                            results: data,
                        };
                    },
                    placeholder: obj.attr('placeholder'),
                    minimumInputLength: 3,
                }
            });
        });
    }

    function generateSummernote(){

        $('.summernote').summernote(
            {
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['fontname', ['fontname']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview', 'help']],
                  ],
            }
        );
        
    }

    function getAjaxSelectedRole()
    {
        $('#ajax-post-data').change( function( e ){
            var obj = $(this);
            var targetResponse = $('#target-ajax-response');
            var selected = obj.val();
            var url = obj.data('url');

            var data = {
                'id' : selected
            };
            
            $.post(url, data, function(response){
                targetResponse.show();
                
                if(!response.data){
                    targetResponse.hide();
                }

            }, "json");


        });
    }

    function generateDaterangepicker()
    {
        $('#daterange-btn').daterangepicker(
            {
                ranges   : {
                'Today'       : [moment(), moment()],
                'Yesterday'   : [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days' : [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month'  : [moment().startOf('month'), moment().endOf('month')],
                'Last Month'  : [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                },
                startDate: moment().subtract(29, 'days'),
                endDate  : moment()
            },
            function (start, end) {
                $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
            }
        )
    }

    $(document).on('shown.bs.modal', '#admin-modal-form', function(){
        generateSummernote();
        generateSelect2bs5();
        generateSelect2bs5Multiple();
        getAjaxSelectedRole();

        bsCustomFileInput.init();
        
    });

    $(document).on('shown.bs.modal', '#modal-form', function(){
        generateSummernote();
        generateSelect2bs5Modal();
        bsCustomFileInput.init();
        
    });
    
    generateSummernote();
    generateSelect2bs5();
    generateSelect2bs5Modal();
    generateSelect2();
    generateSelect2bs5Multiple();
    getAjaxSelectedRole();

});